import React, { ReactNode } from 'react';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Box } from '../Box';
import { Radio, RadioProps } from '../Radio';
import { Typography } from '../Typography';

const StyledContainerBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral[200]}`,
  borderRadius: theme.spacing(Spacing.Small),
}));

const StyledContainerRadio = styled(Box)<{ $isExpanded?: boolean }>(
  ({ theme, $isExpanded }) => ({
    display: 'flex',
    height: theme.spacing(9),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(Spacing.Small),
    borderBottomLeftRadius: $isExpanded ? 0 : theme.spacing(Spacing.Small),
    borderBottomRightRadius: $isExpanded ? 0 : theme.spacing(Spacing.Small),
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);

interface RadioWithContainerProps extends RadioProps {
  readonly children?: ReactNode;
  readonly isExpanded?: boolean;
  readonly description?: string;
}

export function RadioWithContainer({
  value,
  label,
  onClick,
  children,
  isExpanded,
  description,
  ...rest
}: RadioWithContainerProps) {
  return (
    <StyledContainerBox>
      <StyledContainerRadio $isExpanded={isExpanded}>
        <Radio label={label} onClick={onClick} value={value} {...rest} />
        <Typography color="textSecondary" variant="body">
          {description}
        </Typography>
      </StyledContainerRadio>
      {children}
    </StyledContainerBox>
  );
}
