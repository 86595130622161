import React from 'react';
import { styled } from '../../utils/styled';
import { Typography } from '../Typography';

const StyledTypography = styled(Typography)`
  width: 100%;
`;
export interface MainTitleProps {
  readonly title: string;
  readonly subtitle?: string;
}

export function MainTitle({ title, subtitle }: MainTitleProps) {
  return (
    <>
      <Typography variant="h3">{title}</Typography>
      {subtitle ? (
        <StyledTypography
          color="text.secondary"
          noWrap
          title={subtitle}
          variant="subtitle"
        >
          {subtitle}
        </StyledTypography>
      ) : null}
    </>
  );
}
