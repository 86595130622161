import { Stack, styled } from '@mui/material';
import React, { MouseEvent, ReactNode } from 'react';
import { Spacing } from '../../design-system/spacing';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { StyledAccordion, StyledAccordionSummary } from './styles';

export interface AccordionItemTitleProps {
  readonly title: string;
  readonly subtitle?: boolean;
  readonly iconRight?: ReactNode;
  readonly children?: ReactNode;
  readonly id?: string;
  readonly showSelectAll?: boolean;
  readonly onClickSelectAll?: () => void;
  readonly showClearAll?: boolean;
  readonly onClickClearAll?: () => void;
  readonly isExpanded?: boolean;
}

const StyledTitleStack = styled(Stack)`
  margin-top: ${({ theme }) => theme.spacing(Spacing.Small)};
  margin-right: ${({ theme }) => theme.spacing(Spacing.XLarge)};
`;

export function AccordionItemTitle({
  id,
  title,
  iconRight,
  subtitle,
  children,
  showSelectAll,
  onClickSelectAll,
  showClearAll,
  onClickClearAll,
  isExpanded,
}: AccordionItemTitleProps) {
  const handleSelectAll = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickSelectAll?.();
  };

  const handleClearAll = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickClearAll?.();
  };

  return (
    <StyledAccordion $isExpanded={isExpanded}>
      <StyledAccordionSummary expandIcon={iconRight} id={id}>
        <StyledTitleStack>
          <Typography noWrap variant={subtitle ? 'subtitle' : 'h5'}>
            {title}
          </Typography>
        </StyledTitleStack>
        {showSelectAll ? (
          <Button onClick={handleSelectAll} variant="text">
            <Typography noWrap variant="subtitle">
              Select All
            </Typography>
          </Button>
        ) : null}
        {showClearAll ? (
          <Button onClick={handleClearAll} variant="text">
            <Typography noWrap variant="subtitle">
              Clear
            </Typography>
          </Button>
        ) : null}
      </StyledAccordionSummary>
      {children}
    </StyledAccordion>
  );
}
