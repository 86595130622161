import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Button } from '../Button';

export const StyledButtonContent = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>`
  width: auto;
  height: auto;
  border: 1px solid;
  padding: ${({ theme }) => `${theme.spacing(Spacing.Medium)}`};
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.primary.main : theme.palette.neutral[200]};
  max-width: 258px;
  text-align: start;
`;
