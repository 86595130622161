import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { SwipeableDrawer } from '../SwipeableDrawer';

export const ContentWrapper = styled('div')`
  scrollbar-gutter: stable both-edges;
  overflow-y: auto;
  height: 100%;
`;

export const StyledDrawer = styled(SwipeableDrawer, {
  shouldForwardProp: (prop) => prop !== 'isSmUp',
})<{ isSmUp?: boolean }>`
  .MuiDrawer-paper {
    width: ${({ isSmUp }) => (isSmUp ? '600px' : '100vw')};
    height: ${({ isSmUp }) => (isSmUp ? 'calc(100vh - 144px)' : '')};
    border-top: 0;
    border-top-right-radius: ${({ theme, isSmUp }) =>
      isSmUp ? 0 : theme.borderRadii.mobileDrawer};
    border-top-left-radius: ${({ theme, isSmUp }) =>
      isSmUp ? 0 : theme.borderRadii.mobileDrawer};
    top: 145px;
  }
`;

export const StyledButtonContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noJustifyContent',
})<{ noJustifyContent?: boolean }>`
  display: flex;
  padding: ${({ theme }) => theme.spacing(Spacing.Medium)};
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
  ${({ noJustifyContent }) => noJustifyContent && 'justify-content: flex-end;'}
  width: 100%;
`;

export const Puller = styled('div')`
  width: 80px;
  height: 4px;
  background-color: ${({ theme }) => theme.palette.neutral[400]};
  color: red;
  border-radius: 3px;
  position: absolute;
  left: calc(50% - 32px);
  top: 4px;
`;
