import { Theme } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValidRowModel,
} from '@mui/x-data-grid';
import React from 'react';
import { styled } from '../../utils/styled';

export type {
  GridRenderCellParams,
  GridValidRowModel,
  GridColDef,
  GridRowParams,
};

const headerStyles = (theme: Theme) => ({
  backgroundColor: `${theme.palette.grey[900]}`,
  color: `${theme.palette.background.paper}`,
  '&:focus': {
    outline: 'none',
  },
  border: 'none',
});

const FlexContainer = styled('div')`
  min-height: 600px;
  display: flex;
  flex-direction: column;
`;

const noHeaderStyles = { display: 'none' };

const StyledDataGrid = styled(DataGrid)<{
  noHeaderColumn: boolean;
  noPadding: boolean;
  rows: unknown;
}>(({ theme, noHeaderColumn, noPadding, rows }) => ({
  padding: noPadding ? '0' : '16px',
  border: 'none',
  '--unstable_DataGrid-radius': '0px',
  '& .MuiDataGrid-columnHeaders': noHeaderColumn
    ? noHeaderStyles
    : headerStyles(theme),
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'capitalize',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    '&:focus': {
      outline: 'none',
    },
    borderStyle: 'none',
  },
  '--DataGrid-rowBorderColor': 'none',
  '& .MuiDataGrid-cell': {
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnSeparator': { display: 'none' },
  '& .MuiDataGrid-iconButtonContainer': { visibility: 'visible' },
  '.MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
  '.MuiDataGrid-virtualScroller': {
    height: rows?.length === 0 ? '100px' : null,
  },
}));

const dataGridExtraStyles = (loading: boolean) => ({
  minHeight: loading ? '600px' : 0,
  '& .MuiDataGrid-columnHeader': headerStyles,
  '& .MuiDataGrid-sortIcon': { color: 'background.paper' },
  '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
    { opacity: 0.5 },
  '& .MuiDataGrid-iconButtonContainer': { width: 'unset' },
  '.MuiDataGrid-columnHeader:focus': { outline: 'none' },
  '.MuiDataGrid-cell': {
    color: 'text.secondary',
    fontSize: 16,
  },
});

interface TableProps extends DataGridProps {
  readonly noHeaderColumn?: boolean;
  readonly noPadding?: boolean;
}
export function Table({
  columns,
  rows,
  loading = false,
  noHeaderColumn = false,
  noPadding = false,
  ...rest
}: TableProps) {
  return (
    <FlexContainer>
      <StyledDataGrid
        columns={columns}
        disableColumnMenu
        loading={loading}
        noHeaderColumn={noHeaderColumn}
        noPadding={noPadding}
        rows={rows}
        sx={dataGridExtraStyles(loading)}
        {...rest}
      />
    </FlexContainer>
  );
}
