import { Accordion, AccordionSummary } from '@mui/material';
import { styled } from '../../utils/styled';

export const StyledAccordion = styled(Accordion)<{ $isExpanded?: boolean }>`
  ::before {
    height: 0;
  }
  :first-of-type {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  :last-of-type {
    border-bottom-right-radius: ${({ $isExpanded }) =>
      $isExpanded ? '0px' : '8px'};
    border-bottom-left-radius: ${({ $isExpanded }) =>
      $isExpanded ? '0px' : '8px'};
  }
  box-shadow: none;
  &.Mui-expanded {
    margin: 0;
    min-height: 0;
    .MuiAccordionSummary-root {
      min-height: 0;
      border-bottom-right-radius: 8px;
    }
    .MuiAccordionSummary-content {
      margin: 12px 0;
    }
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-expandIconWrapper {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
