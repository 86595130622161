/// <reference types="vite/client" />
import {
  asyncWithLDProvider,
  LDClient,
  useLDClient,
} from 'launchdarkly-react-client-sdk';

export { useLDClient } from 'launchdarkly-react-client-sdk';

export enum Flags {
  payoutsAggregates = 'use-v2-generate-case-aggregate-controller',
  securityPage = 'security-page',
  scheduleReports = 'schedule-reports',
  caseSettings = 'case-settings',
}

type User = {
  id?: string;
  email?: string | null;
};

export const setupFeatureFlag = async ({
  launchdarklyClient,
  user,
  organizationName,
  caseId,
}: {
  launchdarklyClient: LDClient;
  user: User | null;
  organizationName?: string;
  caseId?: string;
}) => {
  if (launchdarklyClient && user?.email) {
    await launchdarklyClient.identify({
      custom: {
        activeCase: caseId,
        activeOrganization: organizationName,
      },
      email: user.email,
      key: String(user.id),
      kind: 'user',
    });
  }
};

export const useFlag = (name: Flags) => {
  const ldClient = useLDClient();
  if (import.meta.env.VITE_APP_E2E_ENV === 'true') {
    return true;
  }
  return ldClient?.variation(name, false);
};

export const initializeLaunchDarkly = (clientID: string) =>
  asyncWithLDProvider({
    clientSideID: clientID,
    timeout: 5,
  });
