import { SliderProps as SliderPropsMui } from '@mui/material';
import React from 'react';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Box } from '../Box';
import { StyledTypography } from '../PageCardContainer/PageCardContainer.styles';
import { Typography } from '../Typography';
import { BoxLabels, BoxValues, StyledSlider } from './SliderContainer.styles';

const StyledBox = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(Spacing.Small)};
  padding: ${({ theme }) => theme.spacing(Spacing.Small)};
  padding-bottom: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

export interface SliderContainerProps
  extends Pick<
    SliderPropsMui,
    | 'onChange'
    | 'name'
    | 'defaultValue'
    | 'onClick'
    | 'valueLabelDisplay'
    | 'defaultValue'
    | 'max'
    | 'min'
  > {
  readonly value: number[];
  readonly rightLabel: string;
  readonly leftLabel: string;
}

export function SliderContainer({
  rightLabel,
  leftLabel,
  value,
  ...props
}: SliderContainerProps) {
  return (
    <StyledBox>
      <BoxValues>
        <Typography variant="bodyStrong">{value[0] || 0}</Typography>
        <Typography variant="bodyStrong">{value[1] || props.max}</Typography>
      </BoxValues>
      <StyledSlider value={value} {...props} />
      <BoxLabels data-testid="slider-labels-container-id">
        <StyledTypography variant="caption">{leftLabel}</StyledTypography>
        <StyledTypography variant="caption">{rightLabel}</StyledTypography>
      </BoxLabels>
    </StyledBox>
  );
}
