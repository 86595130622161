import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Stack } from '../Stack';
import { EditableStatusBarType } from './types';
import { statusBarItemsMapper } from './utils';

export const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'editableStatus',
})<{ editableStatus: EditableStatusBarType }>`
  padding: ${({ theme }) => theme.spacing(Spacing.Medium)};
  border: 1px solid
    ${({ editableStatus }) => statusBarItemsMapper[editableStatus].color};
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  background-color: ${({ editableStatus }) =>
    statusBarItemsMapper[editableStatus].backgroundColor};
  align-items: center;
  width: 100%;
`;
