import toQueryParams from '../../utils/toQueryParams';
import claimScoreApiServiceClient from '../client';
import {
  CreateCasePayload,
  CreateCaseResponse,
  GetCaseAccountsResponse,
  GetCaseAggregateResultsPayload,
  GetCaseAggregateResultsResponse,
  GetCaseResponse,
  GetClaimDeterminationResultsPayload,
  GetClaimDeterminationResultsResponse,
  UpdateCasePayload,
} from './types';

export const getCaseById = async (caseId: string): Promise<GetCaseResponse> => {
  const { data } = await claimScoreApiServiceClient.get(`/cases/${caseId}`);
  return data;
};

export const getCaseAccounts = async ({
  accountRole,
  accountStatus,
  caseId,
  pageNumber,
  search,
}: {
  accountRole?: string;
  accountStatus?: string;
  caseId: string;
  pageNumber: number;
  search?: string;
}): Promise<GetCaseAccountsResponse> => {
  const { data } = await claimScoreApiServiceClient.get(
    `/cases/${caseId}/accounts?${toQueryParams({
      accountRole,
      accountStatus,
      pageNumber,
      pageSize: 12,
      search,
    })}`,
  );

  return data;
};

export const getClaimDeterminationResults = async ({
  caseID,
  pageNumber,
  pageSize,
  filters,
}: GetClaimDeterminationResultsPayload): Promise<GetClaimDeterminationResultsResponse> => {
  const { data } = await claimScoreApiServiceClient.post(
    `/cases/${caseID}/results?${toQueryParams({ pageNumber, pageSize })}`,
    { filters },
  );

  return data;
};

export const getCaseAggregateResults = async ({
  caseID,
  filters,
}: GetCaseAggregateResultsPayload): Promise<GetCaseAggregateResultsResponse> => {
  const { data } = await claimScoreApiServiceClient.post(
    `/cases/${caseID}/aggregate`,
    { filters },
  );

  return data;
};

export const createCase = async ({
  organizationID,
  ...casePayload
}: CreateCasePayload): Promise<CreateCaseResponse> => {
  const { data } = await claimScoreApiServiceClient.post(
    `/cases/organizations/${organizationID}`,
    casePayload,
  );

  return data;
};

export const updateCase = async ({
  caseID,
  ...casePayload
}: UpdateCasePayload): Promise<string> => {
  const { data } = await claimScoreApiServiceClient.put(
    `/cases/${caseID}`,
    casePayload,
  );
  return data;
};

export const deleteCase = async (caseID: string): Promise<string> => {
  const { data } = await claimScoreApiServiceClient.delete(`/cases/${caseID}`);
  return data;
};
