import { Switch as SwitchUi, Theme } from '@mui/material';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';

const hoverStyles = ({ theme }: { theme: Theme }) => ({
  '&:hover .Mui-checked + .MuiSwitch-track': {
    border: `2px solid ${theme.palette.primary.dark}`,
    background: theme.palette.primary.dark,
  },
  '&:hover .Mui-checked': {
    backgroundColor: `${theme.palette.red[600]}`,
  },
  '&:hover .MuiSwitch-track': {
    border: `2px solid ${theme.palette.neutral[400]}`,
  },
  '&:hover .MuiSwitch-switchBase': {
    backgroundColor: 'unset',
    color: theme.palette.neutral[600],
  },
  '&:hover .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.paper[200],
  },
});
const disabledStyles = ({ theme }: { theme: Theme }) => ({
  '& .Mui-disabled': {
    left: '-1px',
  },
  '& .Mui-disabled.Mui-checked': {
    left: '2px',
  },
  // Disabled not checked
  '& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track': {
    opacity: 1,
    border: `2px solid ${theme.palette.neutral[200]}`,
  },
  // Disabled checked
  '& .Mui-checked.Mui-disabled + .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.neutral[200],
    border: `2px solid ${theme.palette.neutral[200]}`,
  },
  '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked': {
    color: theme.palette.neutral[300],
  },
});

export const StyledSwitchUi = styled(SwitchUi)(({ theme }) => ({
  padding: theme.spacing(Spacing.Tiny),
  width: theme.spacing(Spacing.XLarge),
  height: '25px',
  '& .Mui-checked': {
    left: '-11px',
  },
  '& .MuiSwitch-thumb': {
    width: 9,
    height: 9,
    margin: 3,
    position: 'relative',
    boxShadow: 'none',
    top: '-4px',
    left: '-3px',
  },
  // Resting not checked not disabled
  '& .MuiSwitch-track': {
    borderRadius: theme.borderRadii.mobileDrawer,
    opacity: 1,
    border: `2px solid ${theme.palette.neutral[300]}`,
    backgroundColor: theme.palette.paper[200],
    transition: `opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  },
  '& .MuiSwitch-switchBase': {
    color: theme.palette.neutral[400],
  },
  // Resting checked not disabled
  '& .Mui-checked + .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  '& .Mui-checked.MuiSwitch-switchBase': {
    color: theme.palette.paper[200],
  },
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    opacity: 1,
  },
  '& .MuiSwitch-switchBase.Mui-disabled': {
    color: theme.palette.neutral[300],
  },
  ...hoverStyles({ theme }),
  ...disabledStyles({ theme }),
}));
