export const PATH_VARIABLES = {
  caseId: ':caseId',
  claimId: ':claimId',
};

const PUBLIC = {
  // NO LAYOUT
  pageNotFound: '/404',
  login: '/login',
  forgotPassword: '/forgot-password',
  passwordReset: '/password-reset',
  auth: '/__auth',
  accountActivation: '/account-activation',
  terms: '/terms',
  privacyPolicy: '/privacy',
};

const PRIVATE_BASE = {
  // TOP NAVBAR WITH CONTROLS ONLY
  root: '/',
  dashboard: '/dashboard',
  permissions: '/permissions/:tab?',
  security: '/security',
  scheduledReports: '/scheduled-reports',
  caseSettings: '/case-settings',
};

export const PATHS = {
  PUBLIC,
  PRIVATE_BASE,
};
