import { format, parseISO } from 'date-fns';

const NUMBER = 12;

export const getTodayDate = (): string => new Date().toISOString();

export const getNewDate = (date?: string): Date =>
  date ? new Date(date) : new Date();

export const toISOShortString = (date: string): string =>
  format(parseISO(date), 'yyyy-MM-dd');

export const toUSLocaleShortString = (date: string): string => {
  if (!date) return format(new Date(), 'MM/dd/yyyy');
  return format(parseISO(date), 'MM/dd/yyyy');
};

export const toISOString = (date: Date): string => date.toISOString();

export const convertTo24HourFormat = (time: string): string => {
  const [timeOption, period] = time.split(' ');
  const [hours, minutes] = timeOption.split(':');

  let newHours = Number(hours);

  if (period === 'PM' && newHours !== NUMBER) {
    newHours += NUMBER;
  } else if (period === 'AM' && newHours === NUMBER) {
    newHours = 0;
  }

  const formattedHours = String(newHours).padStart(2, '0');
  return `${formattedHours}:${minutes}`;
};

export const convertTo12HourFormat = (time: string): string => {
  const [hours, minutes] = time.split(':');
  const hour = Number(hours);
  const period = hour < NUMBER ? 'AM' : 'PM';
  const newHours = hour % NUMBER || NUMBER;
  const formattedHours = String(newHours).padStart(2, '0');

  return `${formattedHours}:${minutes} ${period}`;
};

export const dateToFullUTCFormat = (date: string): string =>
  format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

export const dateToWeekDay = (date: string): string =>
  format(date, 'eeee').toLowerCase();
