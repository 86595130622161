import React, { lazy } from 'react';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { Flags, useFlag } from 'ui';
import { ProtectedRoute } from '@components/ProtectedRoute';
import { CaseDashboardProvider } from '@pages/CaseDashboard/CaseDashboardContext';
import { NotFound } from '@pages/NotFound';
import { UsersManagementProvider } from '@pages/UsersManagement/UsersManagementContext';
import { PATHS } from '@utils/paths';
import { PrivateLayout } from '../../../layouts/PrivateLayout';

const Login = lazy(() => import('../../../pages/Login/Login'));
const ForgotPassword = lazy(
  () => import('../../../pages/ForgotPassword/ForgotPassword'),
);
const CaseDashboard = lazy(
  () => import('../../../pages/CaseDashboard/CaseDashboard'),
);
const ClaimForm = lazy(() => import('../../../pages/ClaimForm'));
const UsersManagement = lazy(() => import('../../../pages/UsersManagement'));
const Security = lazy(() => import('../../../pages/Security'));
const AccountActivation = lazy(
  () => import('../../../pages/AccountActivation'),
);
const AuthManagement = lazy(() => import('../../../pages/AuthManagement'));
const ResetPassword = lazy(() => import('../../../pages/ResetPassword'));
const ScheduledReports = lazy(() => import('../../../pages/ScheduledReports'));
const CaseSettings = lazy(() => import('../../../pages/CaseSettings'));

export const useAppRoutes = () => {
  const isSecurityPageActive = useFlag(Flags.securityPage);
  const isScheduledReportsActive = useFlag(Flags.scheduleReports);
  const isCaseSettingsActive = useFlag(Flags.caseSettings);

  const publicRoutes: RouteObject[] = [
    { path: PATHS.PUBLIC.login, element: <Login /> },
    { path: PATHS.PUBLIC.forgotPassword, element: <ForgotPassword /> },
    { path: PATHS.PUBLIC.auth, element: <AuthManagement /> },
    { path: PATHS.PUBLIC.passwordReset, element: <ResetPassword /> },
    {
      path: PATHS.PUBLIC.accountActivation,
      element: <AccountActivation />,
    },
    {
      path: PATHS.PUBLIC.pageNotFound,
      element: <NotFound />,
    },
    {
      path: '*',
      element: <Navigate to="/404" />,
    },
  ];

  const privateClaimFormRoute: RouteObject[] = [
    {
      path: '/',
      element: <PrivateLayout />,
      children: [{ path: '/claim-form', element: <ClaimForm /> }],
    },
  ];

  const privateCaseLayoutRoutes: RouteObject[] = [
    {
      path: PATHS.PRIVATE_BASE.root,
      element: <PrivateLayout withNavbar withSidebar />,
      children: [
        {
          path: '',
          element: <Navigate to={PATHS.PRIVATE_BASE.dashboard} />,
        },
        {
          index: true,
          path: PATHS.PRIVATE_BASE.dashboard,
          element: (
            <CaseDashboardProvider>
              <CaseDashboard />
            </CaseDashboardProvider>
          ),
        },
        {
          path: PATHS.PRIVATE_BASE.permissions,
          element: (
            <UsersManagementProvider>
              <UsersManagement />
            </UsersManagementProvider>
          ),
        },
        {
          path: PATHS.PRIVATE_BASE.security,
          element: (
            <ProtectedRoute hasAccess={isSecurityPageActive}>
              <Security />
            </ProtectedRoute>
          ),
        },
        {
          path: PATHS.PRIVATE_BASE.scheduledReports,
          element: (
            <ProtectedRoute hasAccess={isScheduledReportsActive}>
              <ScheduledReports />
            </ProtectedRoute>
          ),
        },
        {
          path: PATHS.PRIVATE_BASE.caseSettings,
          element: (
            <ProtectedRoute hasAccess={isCaseSettingsActive}>
              <CaseSettings />
            </ProtectedRoute>
          ),
        },
      ],
    },
  ];

  const routes: RouteObject[] = [
    ...publicRoutes,
    ...privateCaseLayoutRoutes,
    ...privateClaimFormRoute,
  ];

  const router = createBrowserRouter(routes);

  return { routes, router };
};

export default useAppRoutes;
