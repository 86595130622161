import {
  styled,
  TextField,
  TextFieldProps as TextFieldPropsMui,
} from '@mui/material';
import React, { useState } from 'react';
import { Spacing } from '../../design-system/spacing';
import { CleanButton } from '../../utils/clean-button';
import { StyledMenuItem } from '../Field/Field.styles';
import { ChevronDownIcon } from '../Icons/ChevronDownIcon';
import { Typography } from '../Typography';

export type ChipSelectProps = TextFieldPropsMui & {
  readonly options: string[];
  readonly startAdornmentTitle?: string;
};

const StyledSelectChip = styled(TextField)(({ theme }) => ({
  textTransform: 'capitalize',
  '& .MuiInputBase-root': {
    maxHeight: '40px',
    borderRadius: '100px',
    padding: 0,
    maxWidth: '384px',
    width: 'fit-content',
  },
  '.MuiSelect-icon': {
    top: 'calc(50% - 0.7em)',
    marginLeft: theme.spacing(Spacing.Small),
    right: '6px',
  },
}));

const StartAdornmentButton = styled(CleanButton)(({ theme }) => ({
  padding: theme.spacing(Spacing.Small),
  paddingLeft: theme.spacing(Spacing.Medium),
  width: 'unset',
}));

export function ChipSelect({
  options,
  startAdornmentTitle,
  ...rest
}: ChipSelectProps) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <StyledSelectChip
      InputProps={{
        startAdornment: (
          <StartAdornmentButton onClick={() => setOpen((prev) => !prev)}>
            <Typography variant="subtitle">{startAdornmentTitle}</Typography>
          </StartAdornmentButton>
        ),
      }}
      SelectProps={{
        onClose: handleClose,
        onOpen: handleOpen,
        open,
        IconComponent: ChevronDownIcon,
      }}
      select
      {...rest}
    >
      {options.map((option) => (
        <StyledMenuItem key={`menu-item-${option}`} value={option}>
          {option}
        </StyledMenuItem>
      ))}
    </StyledSelectChip>
  );
}
