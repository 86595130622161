import React from 'react';
import {
  ColorGreen200,
  ColorGreen600,
  ColorRed200,
  ColorRed600,
  ColorYellow200,
  ColorYellow800,
} from '../../design-system/colors';
import { Typography } from '../Typography';

export const statusBarItemsMapper = {
  editable: {
    label: 'Editable',
    backgroundColor: ColorGreen200,
    text: (
      <Typography color="text.secondary">
        Until <strong>Claim Submission Start Date </strong>
        on November 20, 2024.
      </Typography>
    ),
    color: ColorGreen600,
  },
  limited: {
    label: 'Limited',
    backgroundColor: ColorYellow200,
    text: (
      <Typography color="text.secondary">
        Until <strong>Claim Submission End Date </strong>
        on November 20, 2024.
      </Typography>
    ),
    color: ColorYellow800,
  },
  locked: {
    label: 'Locked',
    backgroundColor: ColorRed200,
    text: (
      <Typography color="text.secondary">
        Contact us to make any changes
      </Typography>
    ),
    color: ColorRed600,
  },
};
