import { TextFieldProps } from '@mui/material';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Spacing } from '../../design-system/spacing';
import { Kind } from '../../types/kind';
import { styled } from '../../utils/styled';
import { WithRequired } from '../../utils/withRequired';
import { Stack } from '../Stack';
import { StyledField } from './Field.styles';
import { countries } from './utils';

const StyledFlagContent = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(Spacing.Tiny)};
  flex-direction: row;
  margin-right: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

const MAX_PHONE_LENGTH = 10;
const COUNTRY_CODE = '+1';

export const getCountryFlag = (dialCode: string) => {
  const newDialCode = dialCode.replace(/[()]/gu, '').trim();
  const formattedDialCode = `+1${newDialCode}`;
  for (const country of countries) {
    if (country.dialCodes && country.dialCodes.includes(formattedDialCode)) {
      return country.flag;
    }
  }
  return '🇺🇸';
};

type PhoneNumberFieldProps = WithRequired<TextFieldProps, 'onChange'> & {
  readonly kind: Kind;
  readonly value: string;
};

export function PhoneNumberField(props: PhoneNumberFieldProps) {
  const { onChange, value: inputValue } = props;
  const [phoneNumber, setPhoneNumber] = useState(
    inputValue.replace(/[()]/gu, '').trim(),
  );
  const [countryFlag, setCountryFlag] = useState('🇺🇸');

  const handlePhoneNumberChange = useMemo(
    () => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      let input = event.target.value.replace(/\D/gu, '');

      if (input.length === 0) {
        setPhoneNumber('');
        setCountryFlag('🇺🇸');
        return;
      }
      if (input.length === 3) {
        setCountryFlag(getCountryFlag(input));
      }

      if (input.length <= 3) {
        input = `(${input.slice(0, 3)}`;
      } else if (input.length <= 6) {
        input = `(${input.slice(0, 3)}) ${input.slice(3)}`;
      } else if (input.length <= MAX_PHONE_LENGTH) {
        input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6)}`;
      } else {
        input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, MAX_PHONE_LENGTH)}`;
      }

      setPhoneNumber(input);
      const eventCopy = {
        ...event,
      } as unknown as ChangeEvent<HTMLInputElement>;
      eventCopy.target.value = input;
      onChange(eventCopy);
    },
    [onChange],
  );

  return (
    <StyledField
      {...props}
      InputProps={{
        startAdornment: (
          <StyledFlagContent>
            {COUNTRY_CODE}
            <div>{countryFlag}</div>
          </StyledFlagContent>
        ),
      }}
      onChange={(event) => {
        handlePhoneNumberChange(event);
      }}
      value={phoneNumber}
    />
  );
}
