import React from 'react';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Divider } from '../Divider';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const TypographyAddFlexStyle = styled(Typography)({
  display: 'flex',
});

const TypographyLabelStyle = styled(Typography)({
  margin: 0,
  width: '96px',
});

const StyledDrawerItem = styled(Stack)`
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(Spacing.Large)};
  :not(style) ~ :not(style) {
    margin-top: ${({ theme }) => theme.spacing(Spacing.None)};
  }
`;
export interface DrawerItemProps {
  readonly label: string;
  readonly item: React.ReactNode;
}

export function DrawerItem({
  label,
  item,
}: DrawerItemProps): React.ReactElement {
  return (
    <>
      <StyledDrawerItem>
        <TypographyLabelStyle
          color="text.secondary"
          title={label}
          variant="caption"
        >
          {label}
        </TypographyLabelStyle>
        <TypographyAddFlexStyle color="text.secondary" variant="body">
          {item}
        </TypographyAddFlexStyle>
      </StyledDrawerItem>
      <Divider noMargin />
    </>
  );
}
